.-approved-estimate {
    padding: 10px 2em;
    text-decoration: none;
    border: none;
    font-family: Arial,Helvetica,sans-serif;
    border-radius: 4px;
    background-color: #00a863;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 100%;
    text-align: center;
    text-decoration: none;
    color: #ffffff !important;
}

.-approve-msg {
    margin: 2.5em 0 0;
    line-height: 6px;
    font-size: 11px;
    color: #9E9C9C;
}

.-total-estimate {
    color: #00a863;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 20px;
    margin-top: 0;
}

center {
     background-color: #f2f2f2;
}

.main {
    width: '100%';
    background-color: #fafafa;
    /* padding: 0 1.5em */
}

.header {
    /* height: 130px; */
    text-align: left;
}

.-header-img {
    width: 215px;
    /* margin-top: 2em; */
    margin-right: -12px;
}


hr {
    border-bottom: none;
    border: 1px solid #00000080;
    margin: 0;
}

.table-row {
    display: inline-flex;
    text-align: left;
    border-bottom: none;
    margin: 0px 0px 0em;
    padding: 8px;
    color: #000000;
}

.order-list .table-row:nth-child(odd), .table-row-odd {
    background: #d9d9d933;
}

.secondary {
    /* width: 522px; */
}

.table-col-1 {
    width: 60%;
    font-size: 13px;
    min-width: 280px;
}

.table-col-2 {
    width: 20%;
    font-size: 13px;
    min-width: 115px;
    padding: 0 0 0 1.5em;
}

.table-col-3 {
    width: 20%;
    font-size: 13px;
    min-width: 100px;
    padding: 0.7em 0.5em 0 0;
}

.table-col-3 > p {
    font-weight: 700;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 1.2em;
    color: #00A863;
    text-align: right;
    margin-right: 0.5em;
}

.-total-row {
    border-bottom: none;
    border-top: 2px solid #eaeaea;
    margin: 0;
    color: #000000;
}

.title1 {
    font-weight: 700;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 1.1em;
    color: #00A863;
}

.subtitle1 {
    color: #000000;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 20px;
    margin: 1.5em 0;
    
}

.content1 {
    /* margin-left: 120px; */
    margin-top: -5px
}

.content2 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    width: 100%;
    /* margin-left: 100px; */
}


p{
    margin:10px 0;
    padding:0;
}
table{
    border-collapse:collapse;
}
h1,h2,h3,h4,h5,h6{
    display:block;
    margin:0;
    padding:0;
}
img,a img{
    border:0;
    height:auto;
    outline:none;
    text-decoration:none;
}
body,#bodyTable,#bodyCell{
    height:100%;
    margin:0;
    padding:0;
    width:100%;
}
.mcnPreviewText{
    display:none !important;
}
#outlook a{
    padding:0;
}
img{
    -ms-interpolation-mode:bicubic;
}
table{
    mso-table-lspace:0pt;
    mso-table-rspace:0pt;
}
.ReadMsgBody{
    width:100%;
}
.ExternalClass{
    width:100%;
}
p,a,li,td,blockquote{
    mso-line-height-rule:exactly;
}
a[href^=tel],a[href^=sms]{
    color:inherit;
    cursor:default;
    text-decoration:none;
}
p,a,li,td,body,table,blockquote{
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%;
}
.ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
    line-height:100%;
}
a[x-apple-data-detectors]{
    color:inherit !important;
    text-decoration:none !important;
    font-size:inherit !important;
    font-family:inherit !important;
    font-weight:inherit !important;
    line-height:inherit !important;
}
#bodyCell{
    padding:10px;
}
.templateContainer{
    max-width:600px !important;
}
a.mcnButton{
    display:block;
}
.mcnImage,.mcnRetinaImage{
    vertical-align:bottom;
}
.mcnTextContent{
    word-break:break-word;
}
.mcnTextContent img{
    height:auto !important;
}
.mcnDividerBlock{
    table-layout:fixed !important;
}

.-total {
    letter-spacing: 0;
    color: #ffffff;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 1em;
    padding: 10px 15px;
    background-color: #4E3524;
    margin-top: 20px;
    text-align: left;
    font-weight: 600;
    line-height: 25px;
}

.-company-details {
    padding: 1.5em 0px;
    color: #000000;
    font-family: Helvetica;
    line-height: 0.8em;
    float: left;
    text-align: left;
}
.footer-details {
    float: right;
    padding: 1.5em 0px;
}
.footer-details > a {
    margin-right: -10px;
}
.-top-header {
    /* float: left; */
    width:100% !important;
    font-size: 1.1em;
    font-weight: 400;
    margin-top: 1.3em;
    color: black;
    font-style: normal;
    line-height: 19px;
}
.-header-name {
    font-weight: 700;
    color: #00A863;
}
.-total-amount {
 
    position: relative;
    /* top: 2px; */
    margin-left: 5px;
}