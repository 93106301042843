.main {
    /* position: absolute; */
    padding-left: 5%;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
}

.secondary {
    /* width: 522px; */
}
.title1 {
    font-weight: bold;
    color: #707070;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
}

.subtitle1 {
    color: #707070;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px
}

.content1 {
    margin-top: -5px;
    text-align: center;
    width: 100%;
}

.content2 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    width: 100%;
}